import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { TextEdit, SelectEdit } from "./editControls";
import Stack from "react-bootstrap/esm/Stack";
import texts from "./texts";

class JoinEventFields extends React.Component {
  state = {};

  render() {
    const { buttonText, registrationFields, data, clubName, handleSubmit, handleBack } = this.props;

    return (
      <React.Fragment>
        <Form>
          {registrationFields.map((field) => {
            if (field.type === "select") {
              return (
                <SelectEdit
                  key={field.name}
                  id={field.name}
                  label={field.title}
                  items={field.choices}
                  value={data[field.name]}
                  helpText={field.helpText}
                  disabled={true}
                />
              );
            }
            return <TextEdit id={field.id} label={field.label} value={field.value} disabled={true} />;
          })}
          <Stack direction="horizontal" gap={3} className="mt-5">
            <Button variant="secondary" size="lg" onClick={handleBack}>
              BACK
            </Button>
            <Button variant="primary" size="lg" onClick={handleSubmit}>
              {buttonText}
            </Button>
          </Stack>
          <div className="text-muted mt-2 mb-5">{texts.confirmPrivacy(buttonText, clubName)}</div>
        </Form>
      </React.Fragment>
    );
  }
}

export default JoinEventFields;
