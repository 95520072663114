import React from "react";

import { Helmet } from "react-helmet-async";

import { loadStripe } from "@stripe/stripe-js";

import StripeSettingsContext from "../../contexts/stripeContext";

import MainMenu from "../../components/mainMenu";
import { withSettings } from "../../common/withSettings";
import { useQueryParams } from "../../common/queryParams";
import ProcessingModal from "../../common/processingModal";
import { Page } from "./registerLinkBuilder";
import Join from "./join";

class RegisterApp extends React.Component {
  state = {
    processing: false,
    isLoading: true,

    stripePromise: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: false });

    const { stripePublishableKey } = this.props.settings;
    const stripePromise = await loadStripe(stripePublishableKey);

    this.setState({ stripePromise });
  }

  render() {
    const { loading, processing, stripePromise } = this.state;

    return (
      <StripeSettingsContext.Provider value={stripePromise}>
        <Helmet>
          <title>Register for an event - ATS</title>
        </Helmet>
        <MainMenu hideMenu={true} />
        <GetPage loading={loading} processing={processing} />
      </StripeSettingsContext.Provider>
    );
  }
}

function GetPage(props) {
  const { loading, processing } = props;
  const queryParams = useQueryParams();

  if (processing || loading) return <ProcessingModal text="Loading..." />;

  if ("page" in queryParams) {
    if (queryParams.page === Page.PlannedEvent) return <Join data={queryParams} />;
  }

  return <Join data={queryParams} />;
}

export default withSettings(RegisterApp);
