import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { TextEdit, SelectEdit } from "./editControls";
import { PaymentElement } from "@stripe/react-stripe-js";
import { withStripeElements } from "./withStripeSettings";
import Stack from "react-bootstrap/esm/Stack";
import texts from "./texts";

class DonateAndJoinEventFields extends React.Component {
  state = {};

  handleSubmit = (e) => {
    if (e !== undefined) e.preventDefault();

    const { stripe, elements, handleSubmit } = this.props;
    handleSubmit(stripe, elements);
  };

  render() {
    const { registrationFields, data, buttonText, clubName, handleBack } = this.props;

    return (
      <React.Fragment>
        <Form>
          {registrationFields.map((field) => {
            if (field.type === "select") {
              return (
                <SelectEdit
                  key={field.name}
                  id={field.name}
                  label={field.title}
                  items={field.choices}
                  value={data[field.name]}
                  helpText={field.helpText}
                  disabled={true}
                />
              );
            }
            return <TextEdit id={field.id} label={field.label} value={field.value} disabled={true} />;
          })}
          <div className="mt-5">
            <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
          </div>
          <Stack direction="horizontal" gap={3} className="mt-5">
            <Button variant="secondary" size="lg" onClick={handleBack}>
              BACK
            </Button>
            <Button variant="primary" size="lg" onClick={this.handleSubmit}>
              {buttonText}
            </Button>
          </Stack>
          <div className="text-muted mt-2 mb-5">{texts.confirmPrivacy(buttonText, clubName)}</div>
        </Form>
      </React.Fragment>
    );
  }
}

export default withStripeElements(DonateAndJoinEventFields);
