import Form from "react-bootstrap/Form";

export function DateEdit(props) {
  const { id, label, value, helpText, onChange } = props;

  return (
    <Form.Group key={id} className="mt-3">
      <Form.Label className="fw-bold">{label}</Form.Label>
      <Form.Control type="date" placeholder={label} id={id} value={value} onChange={onChange} />
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
    </Form.Group>
  );
}

export function SelectEdit(props) {
  const { id, label, items, value, helpText, emptyText, onChange, disabled, sortByKeys } = props;

  let keys = Object.keys(items);
  if (sortByKeys) {
    keys.sort();
  }

  return (
    <Form.Group key={id} className="mt-3">
      <Form.Label className="fw-bold">{label}</Form.Label>
      <Form.Select id={id} onChange={onChange} value={value} disabled={disabled ?? false}>
        <option key="empty" value="">
          {emptyText ?? `Select ${label}`}
        </option>
        {keys.map((d) => (
          <option key={d} value={d}>
            {items[d].name ?? items[d]}
          </option>
        ))}
      </Form.Select>
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
    </Form.Group>
  );
}

export function TextEdit(props) {
  const { id, label, value, placeholder, disabled, helpText, type, onChange, onBlur } = props;

  return (
    <Form.Group key={id} className="mt-3">
      {label && <Form.Label className="fw-bold">{label}</Form.Label>}
      <Form.Control type={type ?? "text"} placeholder={placeholder ?? label} id={id} value={value} disabled={disabled} onChange={onChange} onBlur={onBlur} />
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
    </Form.Group>
  );
}
