import React from "react";

import Table from "react-bootstrap/Table";

class EventFields extends React.Component {
  render() {
    const { name, players, startDate, endDate, registerByDate, whatsAppGroup } = this.props;

    return (
      <React.Fragment>
        <h1>{name}</h1>
        <Table striped>
          <tbody>
            <tr>
              <td className="fw-bold">Start&nbsp;Date</td>
              <td>{startDate.toLocaleString({}, { month: "long", day: "numeric", weekday: "long", hour: "numeric", hour12: true, minute: "2-digit" })}</td>
            </tr>
            <tr>
              <td className="fw-bold">End&nbsp;Date</td>
              <td>{endDate.toLocaleString({}, { month: "long", day: "numeric", weekday: "long", hour: "numeric", hour12: true, minute: "2-digit" })}</td>
            </tr>
            <tr>
              <td className="fw-bold">Join&nbsp;until</td>
              <td>{registerByDate.toLocaleString({}, { month: "long", day: "numeric", weekday: "long", hour: "numeric", hour12: true, minute: "2-digit" })}</td>
            </tr>
            <tr>
              <td className="fw-bold">Players</td>
              <td>{players}</td>
            </tr>
            {whatsAppGroup && (
              <tr>
                <td className="fw-bold">WhatsApp</td>
                <td>
                  <a href={whatsAppGroup} target="_blank" rel="noreferrer">
                    Join the WhatsApp group
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default EventFields;
