import React from "react";

import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";

class LeagueFields extends React.Component {
  render() {
    const { clubName, address, courts, leagueName, players, startDate, endDate, registerByDate, whatsAppGroup } = this.props;

    return (
      <Table striped>
        <tbody>
          <tr>
            <td className="fw-bold">Club</td>
            <td>
              <Stack gap={1}>
                <div className="fw-bold"> {clubName}</div>
                <div>{address}</div>
                <div>{courts}</div>
              </Stack>
            </td>
          </tr>
          <tr>
            <td className="fw-bold">League Name</td>
            <td>{leagueName}</td>
          </tr>
          <tr>
            <td className="fw-bold">Level of Play</td>
            <td>Improver to Advance</td>
          </tr>
          <tr>
            <td className="fw-bold">Start&nbsp;Date</td>
            <td>{startDate.toLocaleString({}, { month: "long", day: "numeric", weekday: "long", hour: "numeric", hour12: true, minute: "2-digit" })}</td>
          </tr>
          <tr>
            <td className="fw-bold">End&nbsp;Date</td>
            <td>{endDate.toLocaleString({}, { month: "long", day: "numeric", weekday: "long", hour: "numeric", hour12: true, minute: "2-digit" })}</td>
          </tr>
          <tr>
            <td className="fw-bold">Join&nbsp;until</td>
            <td>{registerByDate.toLocaleString({}, { month: "long", day: "numeric", weekday: "long", hour: "numeric", hour12: true, minute: "2-digit" })}</td>
          </tr>
          {players > 0 && (
            <tr>
              <td className="fw-bold">Players</td>
              <td>{players}</td>
            </tr>
          )}
          {whatsAppGroup && (
            <tr>
              <td className="fw-bold">WhatsApp</td>
              <td>
                <a href={whatsAppGroup} target="_blank" rel="noreferrer">
                  Join the WhatsApp group
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default LeagueFields;
