import { PlayerApi } from "./atsApi";

class PlannedEventApi {
  constructor() {
    this.api = new PlayerApi("planned-event-new");
  }

  getJoinData = (plannedEventId) => this.api.getRequest(`join-data/${plannedEventId}`);
  createPaymentIntent = (plannedEventId, donate) => this.api.postRequest(`create-payment-intent/${plannedEventId}/${donate}`);
  joinEventNoDonation = (plannedEventId, data) => this.api.getRequest(`join-event-no-donation/${plannedEventId}?data=${data}`);
  leaveEvent = (plannedEventId) => this.api.postRequest(`leave-event/${plannedEventId}`);
}

const plannedEventApi = new PlannedEventApi();
export default plannedEventApi;
