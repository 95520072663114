import React from "react";
import Alert from "react-bootstrap/Alert";

const texts = {
  confirmPrivacy: (button, clubName) => (
    <React.Fragment>
      <small>
        By clicking "{button}", you confirm that you have read and agree to the terms of our{" "}
        <a href="https://ats.tennis/privacy-policy.pdf" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        {clubName && ` and you acknowledge that you are able to use the courts at ${clubName}.`}
      </small>
    </React.Fragment>
  ),

  contribution: () => (
    <Alert variant="primary">
      ATS is free to use and completely ad-free. Like Wikipedia, it relies on the generosity of its users. Contributions are entirely optional but greatly
      appreciated - they help cover hosting and administrative costs, maintain and improve the platform, and support ongoing efforts to make ATS even better for
      everyone.
    </Alert>
  ),

  join: {
    cancellationPolicy: (registerByDate) =>
      `Cancel any time before ${registerByDate.toLocaleTimeString()} on ${registerByDate.toLocaleDateString()} for a full refund.`,

    confirmCanUseCourts: (name, registerByDate) =>
      `By clicking "CONFIRM", you acknowledge that you are able to use the courts at ${name}. Remember that you can cancel any time before ${registerByDate.toLocaleTimeString()} on ${registerByDate.toLocaleDateString()} for a full refund.`,

    share: (name) => (
      <React.Fragment>
        Now that you are registered, let's add more players: <strong>share</strong> this league with friends and challenge them to join you on the courts at{" "}
        <strong>{name}</strong>.
      </React.Fragment>
    ),
  },

  register: {
    cancellationPolicy: (registerByDate) => `Cancel any time before ${registerByDate.toLocaleTimeString()} on ${registerByDate.toLocaleDateString()}`,

    share: (name) => (
      <React.Fragment>
        Now that you are registered, let's add more players: <strong>share</strong> this event with friends and challenge them to join you on the courts for{" "}
        <strong>{name}</strong>.
      </React.Fragment>
    ),
  },
};

export default texts;
