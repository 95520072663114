import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";

import { withQueryParams } from "../../common/queryParams";
import { getLink } from "../../common/appLinkBuilder";
import Image from "react-bootstrap/esm/Image";

class ConclusionPage extends React.Component {
  state = {};
  render() {
    const {
      page,
      name,
      data: { tennisPersonality, properties },
      fadeTimeout,
    } = this.props;
    const { shared } = this.props.queryParams;

    return (
      <Container className="mt-5">
        <Row className="mt-2 mb-5">
          <Col>
            <h1>{shared ? `${name}'s ` : "Your"} Tennis Personality</h1>
          </Col>
        </Row>
        <FadeIn delay={fadeTimeout} key={page}>
          <Row className="mt-4">
            <Col>
              <h2 className="text-primary fw-bold ms-n3" style={{ fontSize: "35px" }}>
                {tennisPersonality.name}
              </h2>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Image src={getLink(`${tennisPersonality.icon}.png`)} fluid roundedCircle />
            </Col>
            <Col className="text-start">{tennisPersonality.description}</Col>
          </Row>
          {properties.map((p) => (
            <React.Fragment key={p.name}>
              <Row className="mt-3" key={p.name}>
                <Col>
                  <h1 className="text-secondary">{p.name}</h1>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="text-start">{p.description}</Col>
              </Row>
            </React.Fragment>
          ))}
        </FadeIn>
      </Container>
    );
  }
}

export default withQueryParams(ConclusionPage);
