import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import Stack from "react-bootstrap/Stack";

import { Helmet } from "react-helmet-async";
import ProcessingModal from "../../common/processingModal";
import joinApi from "../../api/joinApi";
import { toast } from "react-toastify";
import Rules from "../tournament/rules";
import { getSessionId, isAuthenticated } from "../../common/auth";
import Register from "../../common/register";
import JoinEventFields from "../../common/joinEventFields";
import { withStripePromise } from "../../common/withStripeSettings";
import { Elements } from "@stripe/react-stripe-js";
import { getPlayerApiUrl } from "../../api/apiUrlBuilder";
import LeagueFields from "./leagueFields";
import ConfirmModal, { ConfirmModalHelper } from "../../common/confirmModal";
import { getLink } from "../../common/appLinkBuilder";
import { IoIosCopy, IoMdShare } from "react-icons/io";
import share from "../../common/share";
import texts from "../../common/texts";
import DonateAndJoinEventFields from "../../common/donateAndJoinEventFields";
import { SelectEdit, TextEdit } from "../../common/editControls";

class Join extends React.Component {
  state = {
    processing: false,
    loaded: false,

    modal: "",
    confirmModalData: ConfirmModalHelper.Empty,

    plannedEventId: this.props.data.plannedEventId,
    clubName: "",
    address: "",
    courts: "",
    leagueName: "",
    registered: false,
    players: 0,
    startDate: new Date("2024-01-01"),
    endDate: new Date("2024-01-01"),
    registerByDate: new Date("2024-01-01"),
    registrationOpen: false,
    clientSecret: "",
    eventRules: [],
    registrationFields: [],
    whatsAppGroup: "",

    donate: 0,
    donateSelect: "",
    donateText: "",

    data: {},

    page: 0,
  };

  async componentDidMount() {
    let { errorMessage } = this.props.data;

    if (errorMessage) {
      if (errorMessage.indexOf("failed authentication") !== -1) {
        errorMessage = "Payment authentication failed, please try again...";
      }

      toast.error(errorMessage);

      const url = new URL(window.location);
      url.searchParams.set("errorMessage", "");
      window.history.pushState(null, "", url);
    }

    await this.refreshRequestData();
  }

  async refreshRequestData() {
    this.setState({ processing: true });

    const { success, data, message } = await joinApi.getJoinData(this.state.plannedEventId);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    const {
      clubName,
      address,
      courts,
      leagueName,
      registered,
      players,
      startDate,
      endDate,
      registerByDate,
      registrationOpen,
      eventRules,
      registrationFields,
      whatsAppGroup,
    } = data;

    this.setState({
      processing: false,
      loaded: true,
      clubName,
      address,
      courts,
      leagueName,
      registered,
      players,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      registerByDate: new Date(registerByDate),
      registrationOpen,
      eventRules,
      registrationFields: registrationFields?.fields ?? [],
      whatsAppGroup: whatsAppGroup ?? "",
    });
  }

  handleLeaveEvent = async () => {
    this.setState({ processing: true });

    const { success, message } = await joinApi.leaveEvent(this.state.plannedEventId);
    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    window.location.reload();
  };

  handleSubmit = async (stripe, elements) => {
    if (!stripe || !elements) {
      return;
    }

    const { plannedEventId, data } = this.state;
    const auth = getSessionId();
    const dataB64 = btoa(JSON.stringify(data));

    this.setState({ processing: true });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${getPlayerApiUrl()}/join/stripe-join/${plannedEventId}?AtsSessionId=${auth}&data=${dataB64}`,
      },
    });

    this.setState({ processing: false });

    if (error.type === "card_error" || error.type === "validation_error") {
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred.");
    }
  };

  handleSubmitNoDonation = async () => {
    const dataB64 = btoa(JSON.stringify(this.state.data));

    this.setState({ processing: true });

    const { success, status, message } = await joinApi.joinNoDonation(this.state.plannedEventId, dataB64);

    if (!success && status !== 239) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }
  };

  handleDonateSelectChange = (e) => {
    const { registrationFields } = this.state;

    switch (e.target.value) {
      case "0":
        this.setState({ donate: 0, donateSelect: e.target.value, page: registrationFields.length > 0 ? 2 : 3 });
        break;
      case "3":
        this.setState({ donate: 300, donateSelect: e.target.value });
        break;
      case "5":
        this.setState({ donate: 500, donateSelect: e.target.value });
        break;
      case "7":
        this.setState({ donate: 700, donateSelect: e.target.value });
        break;
      default:
        this.setState({ donateSelect: e.target.value, page: 1 });
    }
  };

  handleDonateTextChange = async (e) => {
    let donate = parseFloat(e.target.value);
    if (isNaN(donate)) {
      this.setState({ donate: 0, donateText: e.target.value });
      return;
    }

    donate *= 100;
    this.setState({ donate, donateText: e.target.value });
  };

  handleDonateTextChangeFinished = async (e) => {
    let donate = parseFloat(this.state.donateText);
    if (isNaN(donate)) return;

    donate *= 100;

    this.setState({ processing: true });

    const { success, data, message } = await joinApi.createPaymentIntentForJoin(this.state.plannedEventId, donate);

    if (!success) {
      this.setState({ processing: false });
      toast.error(message);
      return;
    }

    const { clientSecret } = data;

    this.setState({
      clientSecret,
      donate,
      page: this.state.registrationFields.length === 0 ? 3 : 2,
      processing: false,
    });
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.id] = e.target.value;
    this.setState({ data });
  };

  render() {
    const {
      processing,
      loaded,

      modal,
      confirmModalData,

      plannedEventId,
      clubName,
      address,
      courts,
      leagueName,
      registered,
      players,
      startDate,
      endDate,
      registerByDate,
      registrationOpen,
      clientSecret,
      eventRules,
      registrationFields,
      whatsAppGroup,

      donate,
      donateSelect,
      donateText,

      data,

      page,
    } = this.state;

    const { stripePromise } = this.props;

    const isAuth = isAuthenticated();
    const donateSelectChoices = {
      0: "No",
      //3: "Yes, £3",
      //5: "Yes, £5",
      //7: "Yes, £7",
      "-1": "Yes",
    };

    const donationChosen = (donateSelect === "-1" && donate > 0) || donateSelect === "0";

    let canRequest = true;
    const missingFields = [];
    registrationFields.forEach((field) => {
      if (!data[field.name]) {
        canRequest = false;
        missingFields.push(field.name);
      }
    });

    if (!loaded) return <ProcessingModal show={true} text="Loading..." />;

    return (
      <React.Fragment>
        <Helmet>
          <title>Join a league - {clubName}</title>
        </Helmet>
        <ProcessingModal show={processing} />
        <ConfirmModal show={modal === ConfirmModalHelper.Name} data={confirmModalData} />
        <Container fluid="md">
          <Row>
            <Col>
              <h1>Join the league</h1>
              <LeagueFields
                clubName={clubName}
                address={address}
                courts={courts}
                leagueName={leagueName}
                players={players}
                startDate={startDate}
                endDate={endDate}
                registerByDate={registerByDate}
                registered={registered}
                whatsAppGroup={whatsAppGroup}
              />
            </Col>
          </Row>
          {!isAuth && (
            <Row className="mt-3 mb-5">
              <Col>
                <h2>Sign in to join the league:</h2>
                <Register inPlace={true} />
              </Col>
            </Row>
          )}
          {isAuth && !registered && !registrationOpen && (
            <React.Fragment>
              <Row>
                <Col>
                  <h4>Registration for this league is currently suspended, please check back later...</h4>
                </Col>
              </Row>
            </React.Fragment>
          )}
          {isAuth && !registered && registrationOpen && (
            <React.Fragment>
              <Row>
                <Col>
                  <h3>Fill in the form to join this league:</h3>
                </Col>
              </Row>
              {page === 0 && (
                <React.Fragment>
                  <Row className="mt-3">
                    <Col>{texts.contribution()}</Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <SelectEdit
                        key="donate"
                        id="donate"
                        label="Would you like to contribute?"
                        items={donateSelectChoices}
                        value={donateSelect}
                        helpText="Would you like to contribute to help cover this league's running cost?"
                        emptyText=""
                        onChange={this.handleDonateSelectChange}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              {page === 1 && (
                <Row className="mt-3">
                  <Col>
                    <TextEdit id="donateSum" label="Contribution amount" value={donateText} type="number" onChange={this.handleDonateTextChange} />
                    <Stack direction="horizontal" gap={3} className="mt-5">
                      <Button variant="secondary" size="lg" onClick={() => this.setState({ page: 0, donateSelect: "", donate: 0, donateText: "" })}>
                        BACK
                      </Button>
                      <Button
                        variant={donationChosen ? "primary" : "danger-outline"}
                        size="lg"
                        disabled={!donationChosen}
                        onClick={this.handleDonateTextChangeFinished}
                      >
                        NEXT
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              )}
              {page === 2 && (
                <Row className="mt-3">
                  <Col>
                    <Form>
                      {registrationFields.map((field) => {
                        if (field.type === "select") {
                          return (
                            <SelectEdit
                              key={field.name}
                              id={field.name}
                              label={field.title}
                              items={field.choices}
                              value={data[field.name]}
                              helpText={field.helpText}
                              onChange={this.handleChange}
                              sortByKeys={true}
                            />
                          );
                        }
                        return <TextEdit id={field.id} label={field.label} value={field.value} onChange={this.handleChange} />;
                      })}
                      <Stack direction="horizontal" gap={3} className="mt-5">
                        <Button
                          variant="secondary"
                          size="lg"
                          onClick={() => (donate === 0 ? this.setState({ page: 0, donateSelect: "", donate: 0, donateText: "" }) : this.setState({ page: 1 }))}
                        >
                          BACK
                        </Button>
                        <Button variant={canRequest ? "primary" : "danger-outline"} size="lg" disabled={!canRequest} onClick={() => this.setState({ page: 3 })}>
                          NEXT
                        </Button>
                      </Stack>
                      {!canRequest && (
                        <Alert variant="danger" className="mt-3">{`Please fill in the missing field${missingFields.length > 1 ? "s" : ""}: ${missingFields.join(
                          ", "
                        )}`}</Alert>
                      )}
                    </Form>
                  </Col>
                </Row>
              )}
              {page === 3 && (
                <Row>
                  <Col>
                    {clientSecret && donate > 0 && (
                      <Elements options={{ clientSecret, appearance: { theme: "stripe" } }} stripe={stripePromise}>
                        <DonateAndJoinEventFields
                          buttonText={`CONTRIBUTE £${(donate / 100).toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })} AND JOIN`}
                          registrationFields={registrationFields}
                          data={data}
                          clubName={clubName}
                          handleSubmit={this.handleSubmit}
                          handleBack={() => this.setState({ page: registrationFields.length === 0 ? 1 : 2 })}
                        />
                      </Elements>
                    )}
                    {(!clientSecret || donate === 0) && (
                      <JoinEventFields
                        buttonText="JOIN NOW"
                        registrationFields={registrationFields}
                        data={data}
                        clubName={clubName}
                        handleSubmit={this.handleSubmitNoDonation}
                        handleBack={() => this.setState({ donateSelect: "", page: registrationFields.length === 0 ? 0 : 2 })}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </React.Fragment>
          )}
          {isAuth && registered && (
            <React.Fragment>
              <Row>
                <Col>
                  <h4>You are now registered for this league</h4>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>{texts.register.share(clubName)}</Col>
              </Row>
              <Row className="mt-3 mb-5" xs={1} md={3}>
                <Col className="mt-2">
                  <Button style={{ width: "180px" }} href={share.join.whatsApp(clubName, plannedEventId)} target="_blank" rel="noreferrer">
                    <img src={getLink(`WhatsApp.png`)} height="33" alt="" />
                  </Button>
                </Col>
                <Col className="mt-2">
                  <Button
                    className="fs-5 fw-bold"
                    style={{ width: "180px" }}
                    onClick={async () => await navigator.share(share.join.navigateData(clubName, plannedEventId))}
                  >
                    <IoMdShare size="30" className="me-2" />
                    Share Link
                  </Button>
                </Col>
                <Col className="mt-2">
                  <Button
                    className="fs-5 fw-bold"
                    style={{ width: "180px" }}
                    onClick={() => {
                      navigator.clipboard.writeText(share.join.copyLink(plannedEventId));
                      toast.info("Link copied", { delay: 2000 });
                    }}
                  >
                    <IoIosCopy size="30" className="me-2" />
                    Copy Link
                  </Button>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2>Manage your registration:</h2>
                  <Button
                    className="mt-2"
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      ConfirmModalHelper.handleShowConfirm(
                        (s) => this.setState(s),
                        "Are you sure you want to leave this league?",
                        () => this.handleLeaveEvent()
                      );
                    }}
                  >
                    LEAVE THE LEAGUE
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          )}
          <Row className="mt-5">
            <Col>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h3>Event Rules</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Rules rules={eventRules} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStripePromise(Join);
