import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { getLink } from "../common/appLinkBuilder";
import { withSettings } from "../common/withSettings";
import Button from "react-bootstrap/esm/Button";

class Footer extends React.Component {
  state = {};
  render() {
    const { defaultApp } = this.props.settings;

    return (
      <Container fluid className="bg-primary text-white mt-auto py-1 fixed-bottom">
        <Row>
          <Col>
            {defaultApp === "join" && (
              <Stack direction="horizontal" gap={2}>
                <span>© 2024 ATS. Powered by</span>
                <span className="bg-primary p-2">
                  <a href="https://ats.tennis" rel="nofollow">
                    <img src={getLink("default.logo.png")} height="30" alt="" />
                  </a>
                </span>
              </Stack>
            )}
            {defaultApp !== "join" && (
              <Stack direction="horizontal" gap={2}>
                <span className="bg-primary p-2">
                  <Button variant="secondary" size="sm" href={`https://ko-fi.com/ats_tennis`} target="about:blank" rel="nofollow">
                    <img src={getLink("kofi_s_logo_nolabel.png")} height="30" alt="" />
                    Support ATS
                  </Button>
                </span>
              </Stack>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withSettings(Footer);
