import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FadeIn from "react-fade-in";
import Button from "react-bootstrap/Button";

import { withQueryParams } from "../../common/queryParams";
import { getLink } from "../../common/appLinkBuilder";
import { Text } from "./utils";

class SupportPage extends React.Component {
  state = {};
  render() {
    const { page } = this.props;

    return (
      <Container className="mt-5">
        <FadeIn delay={400} key={page}>
          <Row className="mt-2 mb-5">
            <Col>
              <h1>Thank You</h1>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>&nbsp;</Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-start">
              If you've enjoyed using <Text value="ATS" />, we'd be grateful for your support.
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-start">A small contribution can help cover platform costs and ensure we can keep going strong.</Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-start">Every bit truly makes a difference.</Col>
          </Row>
          <Row className="mt-4 mb-5">
            <Col>
              <Button variant="primary" size="sm" href={`https://ko-fi.com/ats_tennis`} target="about:blank" rel="nofollow">
                <img src={getLink("kofi_s_logo_nolabel.png")} height="30" alt="" />
                Support ATS
              </Button>
            </Col>
          </Row>
        </FadeIn>
      </Container>
    );
  }
}

export default withQueryParams(SupportPage);
