import { getPlayerApiUrl } from "../api/apiUrlBuilder";

const share = {
  join: {
    whatsApp: function (name, plannedEventId) {
      const nameMessage = `Hey, I've just signed up for a tennis league at *${name.replace("&", "and")}* and thought you might be interested!`;
      const url = `${getPlayerApiUrl()}/go/join-event/${plannedEventId}`;
      const urlMessage = ` It's a great way to enjoy some competitive fun. Check it out and register here: ${url} . Would be awesome to play together!`;

      return `https://wa.me/?text=${encodeURI(`${nameMessage}${urlMessage}`)}`;
    },

    navigateData: function (name, plannedEventId) {
      const nameMessage = `Hey, I've just signed up for a tennis league at *${name.replace("&", "and")}* and thought you might be interested!`;
      const urlMessage = ` It's a great way to enjoy some competitive fun. Check it out and register, would be awesome to play together!`;

      return {
        title: "Check out this league!",
        text: `${nameMessage}${urlMessage}`,
        url: `${getPlayerApiUrl()}/go/join-event/${plannedEventId}`,
      };
    },

    copyLink: function (name, plannedEventId) {
      const nameMessage = `Hey, I've just signed up for *${name.replace("&", "and")}* and thought you might be interested!`;
      const url = `${getPlayerApiUrl()}/go/join-event/${plannedEventId}`;
      const urlMessage = `\n\nIt's a great way to enjoy some competitive fun. Check it out and register here: ${url} . \n\nWould be awesome to play together!`;

      return `${nameMessage}${urlMessage}`;
    },
  },

  register: {
    whatsApp: function (name, plannedEventId) {
      const nameMessage = `Hey, I've just signed up for *${name.replace("&", "and")}* and thought you might be interested!`;
      const url = `${getPlayerApiUrl()}/go/register-event/${plannedEventId}`;
      const urlMessage = ` It's a great way to enjoy some competitive fun. Check it out and register here: ${url} . Would be awesome to play together!`;

      return `https://wa.me/?text=${encodeURI(`${nameMessage}${urlMessage}`)}`;
    },

    navigateData: function (name, plannedEventId) {
      const nameMessage = `Hey, I've just signed up for *${name.replace("&", "and")}* and thought you might be interested!`;
      const urlMessage = ` It's a great way to enjoy some competitive fun. Check it out and register, would be awesome to play together!`;

      return {
        title: "Check out this event!",
        text: `${nameMessage}${urlMessage}`,
        url: `${getPlayerApiUrl()}/go/register-event/${plannedEventId}`,
      };
    },

    copyLink: function (name, plannedEventId) {
      const nameMessage = `Hey, I've just signed up for *${name.replace("&", "and")}* and thought you might be interested!`;
      const url = `${getPlayerApiUrl()}/go/register-event/${plannedEventId}`;
      const urlMessage = `\n\nIt's a great way to enjoy some competitive fun. Check it out and register here: ${url} . \n\nWould be awesome to play together!`;

      return `${nameMessage}${urlMessage}`;
    },
  },
};

export default share;
