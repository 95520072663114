import React from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { LinkBuilder } from "./joinLinkBuilder";
import Badge from "react-bootstrap/esm/Badge";
import Stack from "react-bootstrap/esm/Stack";

class SearchResult extends React.Component {
  render() {
    const { clubId, name, address, courts, leagues } = this.props.data;

    return (
      <React.Fragment key={clubId}>
        <Row className="mt-2 mb-2" xs={1} md={2}>
          <Col>
            <Stack direction="horizontal">
              <h3 className="fw-bold">{name}</h3>
              {leagues.length > 0 && (
                <Badge bg="primary" className="ms-3 mt-n3">
                  Starting soon
                </Badge>
              )}
            </Stack>
          </Col>
          <Col>
            <div>{address}</div>
            <h5>{courts}</h5>
          </Col>
        </Row>
        {leagues.length > 0 &&
          leagues.map((league) => {
            return (
              <Row xs={1} md={2} className="mt-4 mb-3" key={league.id}>
                <Col>
                  <h5>{league.name}</h5>
                  <div>
                    {league.playerCount === 0 ? " Be the first to join" : `${league.playerCount} player${league.playerCount > 1 ? "s" : ""} registered already`}
                  </div>
                  <Button variant="secondary" size="lg" href={LinkBuilder.getJoinLink(league.id)}>
                    JOIN NOW
                  </Button>
                  <div>{`Join until ${new Date(league.registerByDate).toLocaleString(
                    {},
                    { month: "long", day: "numeric", weekday: "long", hour: "numeric", hour12: true, minute: "2-digit" }
                  )}`}</div>
                </Col>
                <Col></Col>
              </Row>
            );
          })}
        {leagues.length === 0 && (
          <React.Fragment>
            <Row xs={1} md={2} className="mt-4 mb-2">
              <Col>
                <Button variant="secondary" size="lg" href={LinkBuilder.getRequestLink(clubId)}>
                  REQUEST AND JOIN NOW
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default SearchResult;
