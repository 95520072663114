import { getPageLink } from "../../common/appLinkBuilder";

const appName = "register";

export const Page = {
  PlannedEvent: "plannedEvent",
};

class RegisterLinkBuilder {
  getPlannedEventLink = (plannedEventId) => getPageLink(appName, Page.PlannedEvent, { plannedEventId: plannedEventId });
}

export const LinkBuilder = new RegisterLinkBuilder();
