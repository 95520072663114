import React from "react";

import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";

import { Helmet } from "react-helmet-async";

import { IoMdShare } from "react-icons/io";

import { withQueryParams } from "../../common/queryParams";
import { Background1, Background2, Background3, Background4 } from "./backgrounds";
import SummaryPage from "./summaryPage";
import TournamentPage from "./tournamentPage";
import DataApi from "../../api/dataApi";
import LeaguePage from "./leaguePage";
import ChampionshipPage from "./championshipPage";
import MatchesPage from "./matchesPage";
import TrophiesPage from "./trophiesPage";
import ConclusionPage from "./conclusionPage";
import { getLink } from "../../common/appLinkBuilder";
import { getPlayerApiUrl } from "./../../api/apiUrlBuilder";
import SupportPage from "./supportPage";

class WrappedApp extends React.Component {
  state = {
    page: 0,
    data: {},
  };

  async componentDidMount() {
    const { playerId, year } = this.props.queryParams;

    const { status, data, message } = await DataApi.getWrappedData(year, playerId);

    if (status !== 200) {
      toast.error(message);
      return;
    }

    this.setState({ data });
  }

  handleSelect = (eventKey) => {
    this.setState({ page: eventKey });
  };

  render() {
    const {
      page,
      data: { name, summary, singlesTournament, doublesTournament, singlesLeague, doublesLeague, championships, matches, trophies, conclusion },
    } = this.state;

    const { playerId, year, shared } = this.props.queryParams;

    const shareData = {
      title: `${name}: ${year} tennis year in numbers`,
      text: `Check out ${shared ? `${name}'s` : "my"} ${year} tennis year numbers`,
      url: `${getPlayerApiUrl()}/go/wrapped/${playerId}?shared=true`,
    };

    const fadeTimeout = 400;
    const scale = 0.75;

    let pageCount = 1;
    if (summary) pageCount++;
    if (singlesTournament) pageCount++;
    if (doublesTournament) pageCount++;
    if (singlesLeague) pageCount++;
    if (doublesLeague) pageCount++;
    if (championships) pageCount++;
    if (matches) pageCount++;
    if (trophies) pageCount++;
    if (conclusion) pageCount++;

    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#282c34" }}>
          <Helmet>
            <meta name="viewport" content={`width=device-width, initial-scale=${scale}, minimum-scale=${scale} maximum-scale=${scale}`} />
            <title>{`${name}: 2024 tennis year in numbers`}</title>
          </Helmet>
          <Image src={getLink("logo.transparent.png")} height="40" style={{ position: "absolute", top: "20px", left: "20px", zIndex: 100 }} />
          {!shared && (
            <Button
              variant="primary"
              onClick={async () => await navigator.share(shareData)}
              style={{ position: "absolute", top: "20px", right: "20px", zIndex: 100 }}
            >
              <IoMdShare size="25" />
            </Button>
          )}
          {page !== pageCount - 1 && shared && (
            <Button variant="primary" href="https://join.ats.tennis" target="_blank" style={{ position: "absolute", top: "20px", right: "20px", zIndex: 100 }}>
              Get ATS in your club
            </Button>
          )}
          <Carousel onSelect={this.handleSelect} interval={null}>
            {summary && (
              <Carousel.Item>
                <Background1 />
                <Carousel.Caption style={{ top: 0 }}>
                  <SummaryPage page={page} name={name} year={year} data={summary} fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {singlesTournament && (
              <Carousel.Item>
                <Background2 />
                <Carousel.Caption style={{ top: 0 }}>
                  <TournamentPage page={page} year={year} data={singlesTournament} type="Singles" fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {doublesTournament && (
              <Carousel.Item>
                <Background3 />
                <Carousel.Caption style={{ top: 0 }}>
                  <TournamentPage page={page} year={year} data={doublesTournament} type="Doubles" fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {singlesLeague && (
              <Carousel.Item>
                <Background4 />
                <Carousel.Caption style={{ top: 0 }}>
                  <LeaguePage page={page} year={year} data={singlesLeague} type="Singles" fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {doublesLeague && (
              <Carousel.Item>
                <Background1 />
                <Carousel.Caption style={{ top: 0 }}>
                  <LeaguePage page={page} year={year} data={doublesLeague} type="Doubles" fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {championships && (
              <Carousel.Item>
                <Background2 />
                <Carousel.Caption style={{ top: 0 }}>
                  <ChampionshipPage page={page} year={year} data={championships} fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {matches && (
              <Carousel.Item>
                <Background3 />
                <Carousel.Caption style={{ top: 0 }}>
                  <MatchesPage page={page} name={name} year={year} data={matches} fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {trophies && (
              <Carousel.Item>
                <Background4 />
                <Carousel.Caption style={{ top: 0 }}>
                  <TrophiesPage page={page} name={name} year={year} data={trophies} fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {conclusion && (
              <Carousel.Item>
                <Background3 />
                <Carousel.Caption style={{ top: 0 }}>
                  <ConclusionPage page={page} name={name} year={year} data={conclusion} fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {
              <Carousel.Item>
                <Background2 />
                <Carousel.Caption style={{ top: 0 }}>
                  <SupportPage page={page} name={name} year={year} data={conclusion} fadeTimeout={fadeTimeout} />
                </Carousel.Caption>
              </Carousel.Item>
            }
          </Carousel>
        </div>
      </React.Fragment>
    );
  }
}

export default withQueryParams(WrappedApp);
