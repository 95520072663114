import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./ats.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { PostHogProvider} from 'posthog-js/react'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
      <PostHogProvider 
      apiKey={"phc_ahSRtrvLLrUtzrXFpUJ6OPslNh8lEXN6e5K7fj4S8nM"}
      options={{api_host: "https://us.i.posthog.com"}}
    >
        <App />
        </PostHogProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
